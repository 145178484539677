const openplate = {
  state: {
    isCollapse: false // 是否折叠左侧菜单
  },
  mutations: {
    CHANGE_CUE_DATE (state, data) {
      state.dateValue = data
    },
    changeMenuToggle: function (state, data) {
      state.isCollapse = data
    }
  },
  actions: {
    // changeCurDate ({ state, commit }, data) {
    //   commit('CHANGE_CUE_DATE', data)
    // }
  }
}

export default openplate
