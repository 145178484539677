// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/font/PingFangSCRegular.eot");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./assets/font/PingFangSCRegular.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./assets/font/PingFangSCRegular.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./assets/font/PingFangSCRegular.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#webfontOTINA1xY" });
// Module
exports.push([module.id, "body,html{width:100%;height:100%;min-width:1200px}*{font-family:PingFangSC-Regular;padding:0;margin:0}[v-cloak]{display:none}@font-face{font-family:pingfang;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");src:local(\"☺\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"svg\");font-weight:400;font-style:normal}.el-table__empty-block{line-height:60px}", ""]);
// Exports
module.exports = exports;
