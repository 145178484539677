<template>
  <div id="app" style="height:100%">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
   html,body{width:100%;height: 100%;min-width: 1200px;}
  *{
    font-family: PingFangSC-Regular;
    padding: 0;
    margin: 0;
  }
  [v-cloak]{display: none;}
  @font-face {
    font-family: pingfang; src: url('./assets/font/PingFangSCRegular.eot');
    src:local('☺'), url('./assets/font/PingFangSCRegular.eot?#iefix') format('embedded-opentype'),
url('./assets/font/PingFangSCRegular.woff') format('woff'), url('./assets/font/PingFangSCRegular.ttf') format('truetype'),url('./assets/font/PingFangSCRegular.svg#webfontOTINA1xY') format('svg');
font-weight: normal;
font-style: normal;
  }
  .el-table__empty-block{
    line-height: 60px;
  }
</style>
