/*
 * @Author: your name
 * @Date: 2020-04-09 15:43:17
 * @LastEditTime: 2022-02-11 16:04:20
 * @LastEditors: konglee
 * @Description: In User Settings Edit
 * @FilePath: /frontend-toolbox/src/router/index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { openplatform } from './openplatform.js'
import { setCookie,getCookie,delCookie } from "@/utils"
const Login = () => import('@/views/Login.vue')
const Home = () => import('@/views/Home.vue')
const Bind = () => import('@/views/bind.vue')
const ForgetPass = () => import('@/views/forgetPass.vue')
const ModifyPass = () => import('@/views/modifyPass.vue')
const Register = () => import('@/views/register.vue')
const RegisterSuccess = () => import('@/views/registerSuccess.vue')
const SubmitSuccess = () => import('@/views/SubmitSuccess.vue')
const EnterpriseInfo = () => import('@/views/EnterpriseInfo.vue')
const Auth = () => import('@/views/auth.vue')
const ApplicantAuth = () => import('@/views/ApplicantAuth.vue')
const AuthSuccess = () => import('@/views/authSuccess.vue')
const AccountInfo = () => import('@/views/AccountInfo.vue')


console.log('openplatform', openplatform)
const currentNav = 'openplatform' // 之后外部传入当前点击的导航
let routerOption = {} // 存储当前的路由对象
const pageOptions = {
  openplatform: openplatform
}
isShowPage(currentNav)
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    beforeEnter: (to, from ,next) => {
      console.log("to123",to)
      if(getCookie("zxtoken")){
        next({path:'/hashcertificate/overview'})
      }else{
        next()
      }
     },
    meta: {}
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {},
    children: [...routerOption]
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: (to, from ,next) => {
      console.log("to123",to)
      if(getCookie("zxtoken")){
        next({path:'/hashcertificate/overview'})
      }else{
        next()
      }
     },
    meta: {}
  },
  {
    path: '',
    redirect: "login"
  },
  {
    path: '/forgetPass',
    name: 'forgetPass',
    component: ForgetPass
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/registerSuccess',
    name: 'registerSuccess',
    component: RegisterSuccess
  },
  {
    path: '/submitSuccess',
    name: 'submitSuccess',
    component: SubmitSuccess,
    meta: {
      requiresAuth: true
  }
  },
  {
    path: '/enterpriseInfo',
    name: 'enterpriseInfo',
    component: EnterpriseInfo,
    meta: {
      requiresAuth: true
  }
  },
  {
    path: '/applicantAuth',
    name: 'applicantAuth',
    component: ApplicantAuth,
    meta: {
      requiresAuth: true
  }
  },
  {
    path: '/authSuccess',
    name: 'authSuccess',
    component: AuthSuccess,
    meta: {
      requiresAuth: true
  }
  },
  {
    path: '/accountInfo',
    name: 'accountInfo',
    component: AccountInfo,
    meta: {
      requiresAuth: true
  }
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    meta: {
      requiresAuth: true
  }
  },
  {
    path: '/bind',
    name: 'bind',
    component: Bind,
    meta: {
      requiresAuth: true
  }
  },
  {
    path: '/modifyPass',
    name: 'modifyPass',
    component: ModifyPass
  },
]
function isShowPage (flag) {
// 根据变量去对象里面取值
  routerOption = pageOptions[flag]
}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


const noLoginPage=[ // 不需要登陆直接访问的页面
  "/documentcenter", // 文档中心
]

// 路由钩子,导航之前
router.beforeEach(async (to, from, next) => {
  // Loading.service() // 开启页面loging状态
  const zxtoken = getCookie('zxtoken')
  if(noLoginPage.includes(to.path)){
    next()
  } else
  if (to.matched.some((record) => record.meta.requiresAuth) && !zxtoken) {
  // 判断目的路由是否需要登陆授权
    window.open(`${window.toolbox_redirectUrl.portal}/openSkill`, '_self')
    // next({
    //   name: 'login',
    // })
  } else {
    next()
  }
})



export default router
