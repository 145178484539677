import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import { baseURL, statusCode, KEYS, retMsgMap } from '@/config'
import { getStorageItem, setStorageItem, removeStorageItem ,getCookie,setCookie,delCookie,getUuid} from '@/utils'
import { redirectUrl ,cookieDomainName} from '@/config'
// import { refreshToken } from '@/api/user'
import { Loading } from 'element-ui'
// 创建实例
const instance = axios.create({
  baseURL,
  timeout: 10000,
  headers: {
    'Cache-Control': 'no-cache'
  }
})

// 本地用于测试 手动添加session
if(location.host.includes("localhost")){
  setCookie("zx-session","MTYxNDU2MjY5OHxOd3dBTkZsSlRWcEJRVUpDTjBSUVMxZFVXRTFVU3pSSE1reEhSVXhJU2xOWlF6VlZVRVJEV1ZoUFVsbzBVRXhRVDFWRFUwSk5TMUU9fLJX-tVoCeJHECPYgen1mS_QD3doAAWgkJflENeYrS1W")
}
// 请求拦截器
instance.interceptors.request.use((conf) => {
 // console.log("5555",getCookie('token'))
  conf.headers['Cloud-Trace-Id'] = getUuid()
  // 在响应中添加token
  // conf.headers['X-XSRF-TOKEN'] = localStorage.getItem('token') ? localStorage.getItem('token') : ''
  conf.headers['X-XSRF-TOKEN'] = getCookie('zxtoken') ? decodeURIComponent(getCookie('zxtoken')) : ''
  if (conf.method == 'get') {
    if (!conf.params) { // 如果这个请求本身不带参数
      Object.assign(conf, { // 给options这个对象添加一个params的参数，属性为timestamp,值为时间戳
        params: {
         // timestamp: new Date().getTime()
        }
      })
    } else {
      Object.assign(conf.params, { // 如果get请求本身带有参数，给options.params 再添加一个key值timestamp,值为时间戳
       // timestamp: new Date().getTime()
      })
    }
  }
  return conf
}, (err) => {
  // 调用element-ui提示错误
  Vue.prototype.$message.error(err)
  Promise.reject(err)
})

const duration = 2000
const responseHandlers = {
  // 未登录,提示duration跳转登陆
  [statusCode.NOT_LOGIN]: (res) => {
    const message = '登录过期，请重新登录'
    Vue.prototype.$message({
      message,
      duration,
      type: 'error'
    })
    // 如果当前页面不是登陆，则跳转到登陆
    if (router.currentRoute.name !== 'login' || router.currentRoute.name !== 'backstageLogin') {
      setTimeout(() => {
        window.open(`${window.toolbox_redirectUrl.portal}/openSkill`, '_self')
        // router.push({
        //   name: 'login',
        //   query: {
        //     redirect: router.currentRoute.fullPath
        //   }
        // })
      }, duration)
    }
    return Promise.resolve(res)
  },
  // token过期，刷新token，重发请求
  [statusCode.TOKEN_EXPIRED]: async (res) => {
    // 保存前一个请求
    const { config } = res
    // 用refreshToken去刷新authToken, refreshToken过期时间比authToken长,等待刷新成功后再进行后续操作
    const refreshTokenResponse = await refreshToken({
      refreshToken: getStorageItem(KEYS.RefreshToken)
    })
    const { code, data = {} } = refreshTokenResponse
    const message = '登录过期，请重新登录'
    // token刷新成功，更新本地authToken和refreshToken
    if (code === statusCode.SUCCESS) {
      const { authToken, refreshTokens } = data
      setStorageItem(KEYS.JWTToken, authToken)
      setStorageItem(KEYS.RefreshToken, refreshTokens)
      // 用新的authToken重新请求
      config.headers[KEYS.JWTToken] = authToken
      // 这边不需要baseURL是因为会重新请求url,url中已经包含baseURL的部分了,如果不修改成空字符串，会变成'api/api/xxxx'的情况
      config.baseURL = ''
      return instance(config)
    }
    // 刷新失败跳转到登陆重新登陆，登陆成功之后跳转回来
    Vue.prototype.$message({
      message,
      duration,
      type: 'error'
    })
    // 重新登陆
    setTimeout(() => {
      window.open(`${window.toolbox_redirectUrl.portal}/openSkill`, '_self')
      // router.push({
      //   name: 'login',
      //   query: {
      //     redirect: router.currentRoute.fullPath
      //   }
      // })
    }, duration)
    return Promise.reject(res)
  }
}

instance.interceptors.response.use(response => {
  return response
}, err => {
 if (err && err.response && err.response.data.retCode == '7011') {
   console.log("err.response.status",err.response.status)
  const message = '用户身份认证失败，请重新登录'
  const duration = 2000
  Vue.prototype.$message({
    message,
    duration,
    type: 'error'
  })
  if (location.href.includes(window.toolbox_redirectUrl.open)) {
    // 测试环境
    //  delCookie("zxtoken",".zhixinblockchain.com")
     delCookie("zxtoken",cookieDomainName)
  } else {
    // 本地
     delCookie("zxtoken") // 兼容本地调试
  }
  localStorage.removeItem('token')
  window.open(`${window.toolbox_redirectUrl.portal}/openSkill`, '_self')
  // router.push({
  //   path: '/login',
  // })
 }
})

// 响应拦截器
instance.interceptors.response.use(async (res) => {
  if(!res){
    return res
  }
  // 后续和后端协商响应状态码，添加统一的状态吗响应
  const { retCode } = res.data
  // 松散路由，不进行响应拦截跳转路由
  const { headers: { 'X-Strict-Route': strictRoute } } = res.config
  if (strictRoute === 'loose') {
    if (retCode !== statusCode.SUCCESS) {
      // 移除登陆相关的信息
      removeStorageItem(KEYS.UserId)
      removeStorageItem(KEYS.JWTToken)
      removeStorageItem(KEYS.RefreshToken)
      // 刷新当前页面
      router.replace(router.currentRoute.fullPath)
    }
    return res.data
  }
  if (typeof responseHandlers[retCode] !== 'function') {
    if (retCode === 0) {
      return res.data
    }
    let flag = false
    for (const key in statusCode) {
      if (key == retCode) {
        flag = true
        res.data.retMsg = statusCode[key]
        if (retCode == 7001 || retCode == 7002) {
         //  document.cookie = 'userData=; expires=Thu, 01 Jan 1970 00:00:00 GMT'
          setTimeout(() => {
            window.open(`${window.toolbox_redirectUrl.portal}/openSkill`, '_self')
            // router.push({
            //   name: 'login',
            //   query: {
            //     redirect: router.currentRoute.fullPath
            //   }
            // })
          }, 2000)
        }
        return res.data
      }
    }
    if (flag == false) {
      return res.data
    }
  } else {
    return res.data
  }
}, (err) => {
  const loadingInstance = Loading.service({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  loadingInstance.close()
  if (err.response != undefined) {
    // console.log(router.history.current.fullPath)
    if (router.history.current.fullPath !== '/') {
      if (err.response.data.retCode === 8000) {
        // document.cookie = 'userData=; expires=Thu, 01 Jan 1970 00:00:00 GMT'
        const message = '登录过期，请重新登录'
        const duration = 2000
        localStorage.removeItem('token')
        Vue.prototype.$message({
          message,
          duration,
          type: 'error'
        })
        window.open(`${window.toolbox_redirectUrl.portal}/openSkill`, '_self')
        // router.push({
        //   name: 'login',
        //   query: {
        //     redirect: router.currentRoute.fullPath
        //   }
        // })
      } else if (err.response.data.retCode === 7011) {
        // 暂时隐藏 modify by liqin 2020.4.22
        const message = '用户身份认证失败，请重新登录1'
        const duration = 2000
        if (location.href.includes(window.toolbox_redirectUrl.open)) {
          // 测试环境
          //  delCookie("zxtoken",".zhixinblockchain.com")
          delCookie("zxtoken",cookieDomainName)
        } else {
          // 本地
           delCookie("zxtoken") // 兼容本地调试
        }
        localStorage.removeItem('token')
        Vue.prototype.$message({
          message,
          duration,
          type: 'error'
        })
        // router.push({
        //   path: '/login',
        // })
        window.open(`${window.toolbox_redirectUrl.portal}/openSkill`, '_self')
        
      } else if (err.response.status === 503) {
        const message = '访问频率过快，请稍后再试'
        const duration = 2000
        Vue.prototype.$message({
          message,
          duration,
          type: 'error'
        })
      }
    }
  } else {
    const message = '服务器内部错误'
    const duration = 2000
    Vue.prototype.$message({
      message,
      duration,
      type: 'error'
    })
  }
  return Promise.reject(err)
})

export default instance
