// Ajax统一状态码
export const statusCode = {
  0: '成功',
  1001: '行业分类（父类）参数为空',
  1002: '行业分类（父类）参数格式不正确',
  1003: '行业分类（子类）参数为空',
  1004: '行业类别输入不正确',
  1005: '参数缺失或不正确',
  1006: '请求报文格式不正确',
  1007: '邮件格式输入不正确',
  1008: '手机号码参数格式不正确',
  1009: '企业营业执照文件上传格式参数错误',
  1010: '电子公函盖章扫描件文件上传参数错误',
  1011: '验证码参数类型不正确',
  1012: '企业归属参数不正确',
  1013: '邮件长度超出范围',
  1014: '名字长度超出范围',
  1015: '密码长度超出范围',
  1016: '新旧密码一致',
  1017: '新密码长度超出范围',
  1019: '统一社会信用代码参数格式不正确',
  1020: '法定代表人参数格式不正确',
  1021: '申请人姓名格式不正确',
  1022: '申请人职务格式不正确',
  1023: '企业地址格式不正确',
  1025: '问题主题格式不正确',
  1026: '问题详情格式不正确',
  1027: '其他信息格式不正确',
  1028: '企业公钥格式不正确',
  1029: '签名串格式不正确',
  1030: '公钥说明格式不正确',
  1031: '身份证(正面)文件上传参数错误',
  1032: '身份证(背面)文件上传参数错误',
  1033: '身份证格式错误',
  1034: '取证网页截图地址格式错误',
  1035: '取证证据名称错误',
  1036: '取证备注错误',
  2001: '添加用户信息失败',
  2002: '账号或密码输入不正确',
  2003: ' 当前登陆密码输入不正确',
  2004: '未购买存证套餐',
  2101: ' 获取行业分类（父类）失败',
  2102: ' 获取行业分类（子类）失败',
  2201: '添加企业营业执照失败',
  2202: '获取企业营业执照失败',
  2203: ' 获取企业营业执照文件是否被取企业认证使用失败',
  2211: '添加身份证正面失败',
  2212: '获取身份证正面失败',
  2221: '添加身份证背面失败',
  2222: '获取身份证背面失败',
  2301: '添加取电子公函盖章失败',
  2302: '获取电子公函盖章失败',
  2303: '获取电子公函盖章是否被企业认证使用失败',
  2401: '添加企业认证信息失败',
  2402: '获取企业认证信息失败',
  2403: '获取企业认证列表失败',
  2404: '获取企业认证列表总数失败',
  // 2405: '更新企业认证失败',
  2405: '企业信息已实名认证通过,不支持修改',
  2406: '企业认证ID无效',
  2407: '已经通过认证，无法更新',
  2501: '企业认证ID无效',
  2502: '企业认证ID无效',
  2503: '企业认证ID无效',
  2504: '企业认证ID无效',
  2601: '添加企业证书失败',
  2602: '获取企业证书失败',
  2603: '更新企业公钥失败',
  2701: '添加客服申请失败',
  2801: '获取企业认证编号日计数失败',
  2802: '获取企业应用编号日计数失败',
  2803: '获取缓存失败',
  2804: '更新缓存失败',
  2805: '删除缓存失败',
  2806: '获取按天计数器缓存失败',
  2807: '回滚按天计数器缓存失败',
  3001: '一个用户只能创建一个企业认证',
  3002: '用户邮箱已经存在',
  3004: '缺少验证码',
  3005: '验证码错误',
  3007: '用户没有实名认证',
  3008: '个人实名认证请求错误，请稍后再试',
  3009: '申请人姓名与身份证号码不匹配，请重试',
  3101: '企业认证未审批通过',
  3102: '企业应用已经存在',
  3103: '通过用户ID获取企业认证ID失败',
  3104: '更新企业认证状态异常',
  3105: '企业实名认证请求错误，请稍后再试',
  3106: '企业名称与信用代码及法人信息不匹配，请重试',
  3108: '取证请求失败',
  3109: '获取取证详情失败',
  3110: '获取取证列表失败',
  3201: '创建企业营业执照文件路径失败',
  3202: '写入企业营业执照文件失败',
  3203: '下载企业营业执照文件失败',
  3204: '企业营业执照文件已被使用',
  3301: '创建电子公函盖章文件路径失败',
  3302: '写入电子公函盖章文件失败',
  3303: '电子公函盖章已被使用',
  3401: '获取行业分类（子类）列表为空',
  3402: '获取行业分类（父类）列表为空',
  3501: '用户尚未注册，用户邮箱未验证',
  3502: '生成验证码失败',
  3503: '已经发送验证码，请稍后再试',
  3504: '未发送验证码或已经过期，请重新发送',
  3505: '邮箱验证码填写有误，请重试',
  3506: '发送验证码邮件失败',
  3507: '发送验证码短信失败',
  3508: '发送通知短信失败',
  3509: '发送通知邮件失败',
  3510: '发送客服申请邮件失败',
  3511: '发送验证码失败，请稍后再试',
  3601: '绑定证书失败',
  3602: '更新证书失败',
  3603: '证书签名解密失败',
  3604: '证书签名不正确',
  3701: '加密出现问题',
  3702: '解密出现问题',
  3703: '读取加密文件出现问题',
  3704:'内部错误，访问tmw失败',
  3705:'内部错误，查询旧密码失败',
  3706:'不能用和原密码相同的密码',
  3801: '创建身份证(正面)文件路径失败',
  3802: '写入身份证(正面)文件失败',
  3803: '下载身份证(正面)文件失败',
  3804: '身份证(正面)文件已被使用',
  3811: '创建身份证(背面)文件路径失败',
  3812: '写入身份证(背面)文件失败',
  3813: '下载身份证(背面)文件失败',
  3714: '身份证(背面)文件已被使用',
  4515: '查询存证数据失败',
  4506: '查询时间不能为空',
  4517: '内部错误，远程调用失败',
  4516: '数据库查询失败',
  7001: '当前用户已在其他地方登陆',
  7011: 'CSRF token校验失败',
  7002: '你在使用忘记密码功能',
  4518: '数据过多，请缩小查询范围'
}

export const projectTitle = '至信链开放平台'

export const baseURL = ''
// KEYS
export const KEYS = {
  JWTToken: '',
  RefreshToken: '',
  UserId: ''
}

export const getBusiDetailType = {
  '1': '⽹页取证',
  '2': 'HASH存证'
}

// 生成至信链证书时候测试地址
const domain = document.domain
export const baseapi= `https://${domain}`

// 前端设置cookie的主域名 一个顶级域名 分为测试(.zhixinblockchain.com)和生产环境(.zxinchain.com)
export const cookieDomainName = '.'+ document.domain.split('.').slice(-2).join('.')
