const Overview = () => import('@/views/openplatform/overview/index.vue')
const Hashcertificate = () => import('@/views/openplatform/hashcertificate/index.vue')
const Hashcertificateview = () => import('@/views/openplatform/hashcertificate/overview/index.vue')
const Hashcertificatedetail = () => import('@/views/openplatform/hashcertificate/overview/detail.vue')
const Documentcenter = () => import('@/views/openplatform/documentcenter/index.vue')

export const openplatform = [
  {
    path: '/overview',
    name: 'overview',
    component: Overview,
    icon: 'icon-zong_lan',
    meta: {
      title: '总览',
      requiresAuth: true
    }
  },
  {
    path: '/hashcertificate',
    name: 'hashcertificate',
    component: Hashcertificate,
    icon: 'icon-cun_zheng',
    meta: {
      title: 'Hash存证api',
      requiresAuth: true
    },
    children: [
      {
        path: '/hashcertificate/overview',
        name: 'hashcertificateoverview',
        component: Hashcertificateview,
        requireAuth: false,
        meta: {
          title: '存证总览',
          requiresAuth: true
        }
      },
      {
        path: '/hashcertificate/detail',
        name: 'hashcertificatedetail',
        isShowMenu: true, // 是否在侧边栏显示 true表示不显示
        component: Hashcertificatedetail,
        meta: {
          title: '存证总览-详情',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/documentcenter',
    name: 'documentcenter',
    component: Documentcenter,
    requireAuth: false,
    icon: 'icon-wenjianjia',
    // redirect: '/documentcenter/accessdetail',
    meta: {
      title: '文档中心',
      requiresAuth: true
    },
    showChildrenRouter: true, // 是否在侧边栏显示子路由 true表示不显示
  }
]
