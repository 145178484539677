/**
 * 根据key获取localStorage的值
 * @param {string} name localStorage中存储的key
 * @returns { any } 找到就返回，未找到就返回undefined
 */
export const getStorageItem = name => {
  if (!name) return;
  const value = window.localStorage.getItem(name);
  return value && value !== "undefined" && JSON.parse(value);
};
/**
 * 将value以key为键保存到localStorage中
 * @param { string } name 存储到localStorage的key
 * @param { any } value 存储到localStorage的value
 * @returns { void }
 */
export const setStorageItem = (name, value) => {
  if (!name) return;
  return window.localStorage.setItem(name, JSON.stringify(value));
};
/**
 * 删除localStorage
 * @param {string} name 删除localStorage中对应的key
 */
export const removeStorageItem = name => {
  if (!name) return;
  return window.localStorage.removeItem(name);
};
/**
 * 清空localStorage
 */
export const clearStorage = () => window.localStorage.clear();

// 检测浏览器内核
export const getBrowserType = () => {
  if (navigator.userAgent.indexOf("Trident") !== -1) {
    return "IE";
  } else if (navigator.userAgent.indexOf("Presto") !== -1) {
    return "OPERA";
  } else if (
    navigator.userAgent.indexOf("AppleWebKit") !== -1 &&
    navigator.userAgent.indexOf("Chrome") !== -1
  ) {
    return "WEBKIT";
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return "FIREFOX";
  } else if (
    navigator.userAgent.indexOf("AppleWebKit") !== -1 &&
    navigator.userAgent.indexOf("Chrome") === -1
  ) {
    return "SAFARI";
  }
};

// cookie
export const getCookie = (name) => {
  var arr = document.cookie.match(new RegExp("(^| )" + name + "=([^;]*)(;|$)"));
  if (arr != null) return unescape(arr[2]);
  return null;
}

export const setCookie = (name, value,domain) => {
  var Days = 1;
  var exp = new Date();
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
  if(!domain){
    document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString() + ";path=/";
  }else{
    document.cookie = name + "=" + escape(value) + ";domain=" + domain + ";expires=" + exp.toGMTString() + ";path=/";
  }
}

export const delCookie = (name,domain) => {
  var exp = new Date()
  exp.setTime(exp.getTime() - 1)
  var cval = getCookie(name)
  if (cval != null)
    if(domain){
      document.cookie = name + '=' + cval +';domain=' +  domain + ';expires=' + exp.toGMTString() + ";path=/"
    }else{
      document.cookie = name + '=' + cval + ';expires=' + exp.toGMTString()+ ";path=/"
    }
    
}

// 生成uuid
export const getUuid = ()=> {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4";  
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); 
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
}